import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  Pagination,
  Paper,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { getNoteList } from "api/admin";
import { useParams, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";

function MachineNotes() {
  const params = useParams();
  const [expanded, setExpanded] = useState(false);
  const [notesData, setNotesData] = useState([]);
  const [page, setPage] = useState(0);
  const [start, setStart] = useState(params.id);
  const [startState, setStartState] = useState(params.start);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(true);

  const cookies = new Cookies();
  const token = cookies.get("token");
  const navigate = useNavigate();
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const getAllNotes = async () => {
    const result = await getNoteList(start, startState, token);
    if (result.status === 200) {
      if (result.data.is_success) {
        setNotesData(result.data.data.notes);
        setPage(result.data.data.totalPages);
      }
    } else {
      setMsg("Something went wrong");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!token) {
      navigate("sign-in");
    }
  }, []);

  useEffect(() => {
    getAllNotes();
  }, [startState]);

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mt={3}>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper sx={{ minHeight: "85vh", borderRadius: "10px" }}>
                  {!loading ? (
                    notesData && notesData.length > 1 ? (
                      <>
                        <Grid container spacing={3}>
                          {notesData.map((value) => (
                            <Grid
                              item
                              xs={12}
                              md={12}
                              mx={4}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Accordion
                                elevation={0}
                                expanded={expanded === value}
                                onChange={handleChange(value)}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon color="info" />}
                                  aria-controls="panel1bh-content"
                                  id="panel1bh-header"
                                >
                                  <MDTypography
                                    color="dark"
                                    variant="h6"
                                    sx={{ width: "33%", flexShrink: 0 }}
                                  >
                                    {value.note
                                      ? value.note.length < 14
                                        ? value.note.charAt(0).toUpperCase() +
                                          value.note.slice(1).slice(0, 12)
                                        : `${
                                            value.note.charAt(0).toUpperCase() +
                                            value.note.slice(1).slice(0, 12)
                                          }...`
                                      : null}
                                  </MDTypography>
                                  <MDTypography
                                    variant="body2"
                                    sx={{ width: "33%", flexShrink: 0 }}
                                  >
                                    Posted By:{" "}
                                    {value.users.length > 0 ? value.users[0].email : null}
                                  </MDTypography>
                                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                                    Created on: {value ? value.createdAt.slice(0, 10) : null}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <MDTypography variant="body2" color="secondary">
                                    {value.note
                                      ? value.note.charAt(0).toUpperCase() + value.note.slice(1)
                                      : null}
                                  </MDTypography>
                                </AccordionDetails>
                              </Accordion>
                            </Grid>
                          ))}
                        </Grid>
                        <MDBox>
                          <Pagination
                            className="p-top-25 "
                            hideNextButton={false}
                            hidePrevButton={false}
                            count={page}
                            onChange={(e, i) => {
                              setStartState(i - 1);
                            }}
                            color="error"
                          />
                        </MDBox>
                      </>
                    ) : (
                      <Grid
                        item
                        xs={12}
                        md={12}
                        mx={4}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          minHeight: "60vh",
                        }}
                      >
                        {" "}
                        <TextSnippetOutlinedIcon fontSize="large" color="dark" />
                        <MDTypography variant="h6">
                          No Notes Available for this Machine
                        </MDTypography>
                      </Grid>
                    )
                  ) : (
                    <Grid
                      item
                      xs={12}
                      md={12}
                      mx={4}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "60vh",
                      }}
                    >
                      <CircularProgress color="info" />
                    </Grid>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </div>
  );
}

export default MachineNotes;
