/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// import { useState } from "react";

// react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";
// import Grid from "@mui/material/Grid";
// import MuiLink from "@mui/material/Link";

// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { adminLogin } from "api/admin";
import Cookies from "universal-cookie";
// import { Grid } from "@mui/material";

// Images
// import bgImage from "assets/images/bg-sign-in-basic.jpeg";

function Signin() {
  const [email, setEmail] = useState(null);
  const navigate = useNavigate();
  const [password, setPassword] = useState(null);
  const [msg, setMsg] = useState("");
  const cookies = new Cookies();
  // const token = cookies.set("token");
  const handleLogin = (event) => {
    if (event.target.name === "email") {
      setEmail(event.target.value);
    } else if (event.target.name === "password") {
      setPassword(event.target.value);
    }
    if (event.target.value === null || event.target.value === "") {
      setMsg("Password/Email cannot be empty");
    } else {
      setMsg("");
    }
  };
  const authenticate = async () => {
    try {
      if (email !== null && password !== null) {
        const result = await adminLogin(email, password);
        if (result.status === 200) {
          if (result.data.is_success) {
            console.log(result.data)
            cookies.set("token", result.data.data.token, { path: "/" });
            navigate("/dashboard");
          } else {
            setMsg(result.message);
          }
        } else {
          setMsg(result.message);
        }
      } else if (email !== "" && password !== "") {
        setMsg("Password/Email cannot be empty");
      } else {
        setMsg("Password/Email cannot be empty");
      }
    } catch (error) {}
  };
  //   const [rememberMe, setRememberMe] = useState(false);

  //   const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <BasicLayout className="sign-in">
      {/* <MDBox className="sidebar">
        <Grid md={6}>
          <h4>PROTEK</h4>
        </Grid>
      </MDBox> */}
      <Card>
        <MDBox
          variant="gradient"
          bgColor="error"
          borderRadius="lg"
          coloredShadow="error"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="large" color="white" my={2}>
            PROTEK ADMIN-PANEL
          </MDTypography>
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                name="email"
                label="Email"
                value={email}
                fullWidth
                onChange={handleLogin}
              />
              <MDTypography my={1} variant="body2" color="error">
                {msg}
              </MDTypography>
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                name="password"
                label="Password"
                value={password}
                onChange={handleLogin}
                fullWidth
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="error" fullWidth onClick={authenticate}>
                sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Signin;
