/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
// import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
// import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
// import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { useNavigate } from "react-router-dom";
import { getAllUsers, getAllMachines } from "api/admin";
import { useEffect, useState } from "react";
import MDSnackbar from "components/MDSnackbar";
import Cookies from "universal-cookie";
import { CircularProgress } from "@mui/material";

function Dashboard() {
  // const { sales, tasks } = reportsLineChartData;
  const navigate = useNavigate();
  const handleUsers = () => {
    navigate(`/users/${0}`);
  };
  const [usersData, setUsersData] = useState([]);
  const [machineData, setMachineData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [msg, setMsg] = useState("");
  const [show, setShow] = useState(false);
  const cookies = new Cookies();
  const token = cookies.get("token");

  const toggleSnackbar = () => {
    setShow(!show);
  };

  const getAllUsersData = async () => {
    try {
      const result = await getAllUsers(0, token);
      if (result.status === 200) {
        if (result.data.is_success) {
          setUsersData(result.data.data);
        } else {
          setMsg("error");
          setShow(true);
        }
      } else {
        setMsg("Something went wrong");
        setShow(true);
      }
    } catch (error) {
      setMsg("Something went wrong");
      setShow(true);
    }
    setLoading(false);
  };
  const getMachineData = async () => {
    try {
      const result = await getAllMachines(token);
      if (result.status === 200) {
        if (result.data.is_success) {
          setMachineData(result.data.data);
        } else {
          setMsg("error");
          setShow(true);
        }
      } else {
        setMsg("Something went wrong");
        setShow(true);
      }
    } catch (error) {
      setMsg("Something went wrong");
      setShow(true);
    }
    setLoading(false);
  };
  useEffect(() => {
    getAllUsersData();
    getMachineData();
  }, []);
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, []);
  return (
    <>
      <MDSnackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        color="error"
        icon="danger"
        title="Error"
        content={msg}
        open={show}
        close={toggleSnackbar}
      />
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={1} className="h-85vh">
          {loading ? (
            <Grid
              item
              xs={12}
              md={12}
              mx={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "60vh",
              }}
            >
              <CircularProgress color="info" />
            </Grid>
          ) : (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox mb={1.5} onClick={handleUsers} className="mouse-event">
                    <ComplexStatisticsCard
                      color="error"
                      icon="person"
                      title="Users"
                      count={usersData.users ? usersData.users.length : 0}
                      percentage={{
                        color: "",
                        amount: "",
                        label: "",
                      }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox mb={1.5} onClick={() => navigate("/notes")} className="mouse-event">
                    <ComplexStatisticsCard
                      color="dark"
                      icon="work"
                      title="Machines"
                      count={machineData.length}
                      percentage={{
                        color: "",
                        amount: "",
                        label: "",
                      }}
                    />
                  </MDBox>
                </Grid>
              </Grid>
              {/* <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="website views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox> */}
              <MDBox my={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={8}>
                    <Projects />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <OrdersOverview data={usersData.users ? usersData.users : null} />
                  </Grid>
                </Grid>
              </MDBox>
            </>
          )}
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default Dashboard;
