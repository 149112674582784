/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import MasterCard from "examples/Cards/MasterCard";
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { getAllMachines } from "api/admin";

// Billing page components
// import PaymentMethod from "layouts/billing/components/PaymentMethod";
// import Invoices from "layouts/billing/components/Invoices";
// import BillingInformation from "layouts/billing/components/BillingInformation";
// import Transactions from "layouts/billing/components/Transactions";

function Billing() {
  const navigate = useNavigate();
  const [machineData, setMachineData] = useState([]);
  const cookies = new Cookies();
  const token = cookies.get("token");
  const getMachineData = async () => {
    try {
      const result = await getAllMachines(token);
      if (result.status === 200) {
        if (result.data.is_success) {
          setMachineData(result.data.data);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (!token) {
      navigate("sign-in");
    }
  }, []);
  useEffect(() => {
    getMachineData();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox mt={2} className="h-80vh">
        <MDBox mb={2}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {machineData.map((value) => (
                  <Grid item xs={12} md={12} lg={6}>
                    <MDBox mb={1.5} className="mouse-event">
                      <SimpleBlogCard
                        title={value.nameEnglish}
                        description={value.descriptionEnglish}
                        action={{
                          type: "internal",
                          route: `/machine-notes/${value._id}/${0}`,
                          color: "dark",
                          label: "See Notes",
                        }}
                      />
                    </MDBox>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Billing;
