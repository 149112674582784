/**
=========================================================
* Soft UI Dashboard React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
// import Icon from "@mui/material/Icon";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

// Soft UI Dashboard Materail-UI example components
// import DataTable from "examples/Tables/DataTable";

// Data
// import data from "layouts/dashboard/components/Projects/data";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from "@mui/material";
import Bill from "layouts/billing/components/Bill";
import { useEffect, useState } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { getAllUsers } from "api/admin";

function Projects() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const cookies = new Cookies();
  const token = cookies.get("token");
  const handleClick = () => {
    setOpen(true);
  };
  const handleReset = () => {
    setShow(true);
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
    setShow(false);
  };

  // const { columns, rows } = data();
  // const [menu, setMenu] = useState(null);

  // const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  // const closeMenu = () => setMenu(null);

  // const renderMenu = (
  //   <Menu
  //     id="simple-menu"
  //     anchorEl={menu}
  //     anchorOrigin={{
  //       vertical: "top",
  //       horizontal: "left",
  //     }}
  //     transformOrigin={{
  //       vertical: "top",
  //       horizontal: "right",
  //     }}
  //     open={Boolean(menu)}
  //     onClose={closeMenu}
  //   >
  //     <MenuItem onClick={closeMenu}>Action</MenuItem>
  //     <MenuItem onClick={closeMenu}>Another action</MenuItem>
  //     <MenuItem onClick={closeMenu}>Something else</MenuItem>
  //   </Menu>
  // );
  const getAllUsersData = async () => {
    try {
      const result = await getAllUsers(0, token);
      if (result.status === 200) {
        if (result.data.is_success) {
          setData(result.data.data);
        }
      }
    } catch (error) {}
  };
  useEffect(() => {
    getAllUsersData();
  }, []);
  return (
    <>
      <MDSnackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        color="success"
        icon="security"
        content="Password was successfully reset"
        open={show}
        autoHideDuration={250}
        close={handleClose}
      />
      <Card>
        <MDBox py={1} px={3} className="flex-end">
          <Button
            disableElevation
            variant="contained"
            color="dark"
            onClick={() => navigate("/users/0")}
          >
            View All
          </Button>
        </MDBox>
        <MDBox py={0} px={3}>
          <Grid container spacing={3}>
            <Grid item lg={12}>
              {data && data.users
                ? data.users.slice(0, 3).map((value) => (
                    <Bill
                      buttonFunction={() => {
                        handleClick(value._id);
                      }}
                      name={value.name ? value.name : ""}
                      email={value.email ? value.email : ""}
                      vat={value.last_login_code ? value.last_login_code : "NA"}
                    />
                  ))
                : null}
            </Grid>
          </Grid>
        </MDBox>
      </Card>
      <Dialog open={open} onClose={handleClose} fullWidth="sm" maxWidth="sm">
        <DialogTitle>Reset User Password</DialogTitle>
        <Divider />
        <DialogContent>
          <MDInput fullWidth label="Enter New Password" />
        </DialogContent>

        <Divider />
        <DialogActions>
          <MDButton variant="contained" color="info" onClick={handleReset}>
            Reset
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Projects;
