/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Pagination,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getServiceList } from "api/admin";
import Cookies from "universal-cookie";

// import projectsTableData from "layouts/tables/data/projectsTableData";

function Tables() {
  // const { columns, rows } = authorsTableData();
  // const { columns: pColumns, rows: pRows } = projectsTableData();
  const [start, setStart] = useState(0);
  const [requestdata, setRequestData] = useState([]);
  const [page, setPage] = useState(0);
  const cookies = new Cookies();
  const token = cookies.get("token");
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const getRequestData = async () => {
    try {
      const result = await getServiceList(start, token);
      if (result.status === 200) {
        if (result.data.is_success) {
          setRequestData(result.data.data.serviceRequest);
          setPage(result.data.data.totalPages + 1);
        } else {
        }
      } else {
      }
    } catch (error) {}
  };
  {
  }
  useEffect(() => {
    getRequestData();
  }, [start]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3} className="h-85vh">
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card elevation={0} className="h-75vh">
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="error"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Request List
                </MDTypography>
              </MDBox>
              <MDBox pt={6}>
                {requestdata
                  ? requestdata.map((value) => (
                      <MDBox mx={2} my={2}>
                        <Accordion
                          elevation={0}
                          key={value}
                          expanded={expanded === value}
                          onChange={handleChange(value)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon color="info" />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <MDTypography
                              variant="body2"
                              sx={{ width: "25%", flexShrink: 0 }}
                              color="dark"
                            >
                              Serial #:<strong>{value.serial_number}</strong>
                            </MDTypography>
                            <MDTypography
                              variant="body2"
                              sx={{ width: "25%", flexShrink: 0 }}
                              color="dark"
                            >
                              Requested By: <strong> {value.company.toUpperCase()}</strong>
                            </MDTypography>
                            <MDTypography
                              sx={{ width: "25%", flexShrink: 0 }}
                              variant="body2"
                              color="dark"
                            >
                              Status:{" "}
                              <span
                                className={
                                  value.status === "processing" ? "status-text" : "success-text"
                                }
                              >
                                {" "}
                                {value.status}
                              </span>
                            </MDTypography>
                            <MDTypography
                              variant="body2"
                              sx={{ width: "25%", flexShrink: 0 }}
                              color="dark"
                            >
                              Requested On: <strong>{value.request_date.slice(0, 10)}</strong>
                            </MDTypography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <MDTypography color="secondary"> {value.description}</MDTypography>
                            <MDTypography color="info" variant="body2">
                              {" "}
                              Email: <span>{value.contact_email}</span>
                            </MDTypography>
                            <MDTypography color="info" variant="body2">
                              {" "}
                              Contact: <span>{value.direct_operator_phone_number}</span>
                            </MDTypography>
                          </AccordionDetails>
                        </Accordion>
                      </MDBox>
                    ))
                  : null}
              </MDBox>
              <Pagination
                className="p-top-25 "
                hideNextButton={false}
                hidePrevButton={false}
                showFirstButton={true}
                showLastButton={true}
                count={page}
                onChange={(e, i) => {
                  setStart(i - 1);
                }}
                color="error"
              />
            </Card>
          </Grid>
          {/* <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Projects Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns: pColumns, rows: pRows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid> */}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
