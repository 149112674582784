import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Pagination,
} from "@mui/material";
import { resetPassword } from "api/admin";
import { getAllUsers } from "api/admin";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import Bill from "./billing/components/Bill";

function Users() {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [start, setStart] = useState(0);
  const [usersData, setUsersData] = useState([]);
  const [userId, setUserId] = useState("");
  const [msg, setMsg] = useState("");
  const cookies = new Cookies();
  const token = cookies.get("token");
  // const params = useParams();
  const page = usersData.totalPages;
  const [password, setPassword] = useState("");
  const handleClick = (value) => {
    setUserId(value);
    setOpen(true);
  };
  const handleReset = () => {
    ChangeUserPassword();
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
    setShow(false);
  };

  const ChangeUserPassword = async () => {
    try {
      const result = await resetPassword(userId, password, token);
      if (result.status === 200) {
        if (result.data.is_success) {
          setMsg("Password reset successfully");
          setShow(true);
        } else {
          setMsg("Weak Password , password reset unsuccessful");
          setShow(true);
        }
      } else {
        setMsg("Something went wrong");
        setShow(true);
      }
    } catch (error) {
      setMsg("Something went wrong");
      setShow(true);
    }
  };

  const getAllUsersData = async () => {
    try {
      const result = await getAllUsers(start, token);
      if (result.status === 200) {
        if (result.data.is_success) {
          setUsersData(result.data.data);
        } else {
          setMsg("Error");
          setShow(true);
        }
      } else {
        setMsg("Something went wrong");
        setShow(true);
      }
    } catch (error) {
      setMsg("Something went wrong");
      setShow(true);
    }
  };

  useEffect(() => {
    if (!token) {
      navigate("sign-in");
    }
  }, []);

  useEffect(() => {
    getAllUsersData();
  }, [start]);

  return (
    <div>
      <MDSnackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        color={msg === "Password reset successfully" ? "success" : "error"}
        icon={msg === "Password reset successfully" ? "" : "cross"}
        content={msg}
        open={show}
        autoHideDuration={250}
        close={handleClose}
      />
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <Grid container spacing={3}>
            <Grid item lg={12}>
              {usersData && usersData.users
                ? usersData.users.map((value) => (
                    <Bill
                      icon="edit"
                      buttonName="edit"
                      buttonFunction={() => {
                        handleClick(value._id);
                      }}
                      name={value.name ? value.name : ""}
                      company=""
                      email={value.email ? value.email : ""}
                      vat={value.last_login_code ? value.last_login_code : "NA"}
                    />
                  ))
                : null}
            </Grid>
            <MDBox>
              <Pagination
                className="p-top-25 "
                hideNextButton={false}
                hidePrevButton={false}
                count={page + 1}
                onChange={(e, i) => {
                  setStart(i - 1);
                }}
                color="error"
              />
            </MDBox>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
      <Dialog open={open} onClose={handleClose} fullWidth="sm" maxWidth="sm">
        <DialogTitle>Reset User Password</DialogTitle>
        <Divider />
        <DialogContent>
          <MDInput
            fullWidth
            label="Enter New Password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>

        <Divider />
        <DialogActions>
          <MDButton variant="contained" color="info" onClick={handleReset}>
            Reset
          </MDButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Users;
