import Api from "api";

export const adminLogin = async (email, password) => {
  try {
    return await Api.post("/admin/auth/login", { email, password });
  } catch {
    return {
      status: 404,
    };
  }
};

export const resetPassword = async (id, password, token) => {
  try {
    return await Api.post(
      "/admin/profile/change-password",
      { id, password },
      {
        headers: {
          Authorization: token,
        },
      }
    );
  } catch {
    return {
      status: 404,
    };
  }
};

export const getAllUsers = async (start, token) => {
  try {
    return await Api.get(`/admin/list/profile/${start}`, {
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    return {
      status: 404,
    };
  }
};

export const getServiceList = async (start, token) => {
  try {
    return await Api.get(`/admin/list/service/request/${start}`, {
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    return {
      status: 404,
    };
  }
};

export const getAllMachines = async (token) => {
  try {
    return await Api.get("/admin/machine/all", {
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    return {
      status: 404,
    };
  }
};

export const getNoteList = async (id, start, token) => {
  try {
    return await Api.get(`/admin/list/notes/${id}/${start}`, {
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    return {
      status: 404,
    };
  }
};
