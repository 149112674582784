/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import ListAltIcon from "@mui/icons-material/ListAlt";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import TimelineItem from "examples/Timeline/TimelineItem";
import { getServiceList } from "api/admin";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function OrdersOverview() {
  const [requestData, setRequestData] = useState([]);
  const [start, setStart] = useState(0);
  const cookies = new Cookies();
  const navigate = useNavigate();
  const token = cookies.get("token");
  const getRequestData = async () => {
    try {
      const result = await getServiceList(start, token);
      if (result.status === 200) {
        if (result.data.is_success) {
          setRequestData(result.data.data.serviceRequest);
        } else {
        }
      } else {
      }
    } catch (error) {}
  };
  {
  }
  useEffect(() => {
    getRequestData();
  }, []);

  return (
    <>
      <Card sx={{ height: "100%" }}>
        <MDBox pt={3} px={3}>
          <div className="sign-in-class space-btw">
            <MDTypography variant="h6" fontWeight="medium">
              Request Overview
            </MDTypography>
            <Button
              disableElevation
              variant="contained"
              color="dark"
              onClick={() => navigate(`/request`)}
            >
              View All
            </Button>
          </div>
          <MDBox mt={0} mb={2}>
            <MDTypography variant="button" color="text" fontWeight="regular">
              <MDTypography display="inline" variant="body2" verticalAlign="middle">
                {" "}
                <ListAltIcon />{" "}
              </MDTypography>
              &nbsp;
              <MDTypography variant="button" color="text" fontWeight="medium">
                Latest Requests
              </MDTypography>{" "}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox p={2}>
          {requestData.slice(0, 8).map((value) => (
            <TimelineItem
              color={value.status === "processing" ? "info" : "success"}
              icon="list"
              title={`Requested by:  ${value.company.toUpperCase()}`}
              dateTime={value.request_date.slice(0, 10)}
            />
          ))}
        </MDBox>
      </Card>
    </>
  );
}

export default OrdersOverview;
